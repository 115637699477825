import React, { useEffect, useState, useRef } from 'react';
import './App.css';

function App() {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [acceptUserInput, setAcceptUserInput] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const [cursorColor, setCursorColor] = useState('green');
  const [isTypingResponse, setIsTypingResponse] = useState(false);
  const [showMatrix, setShowMatrix] = useState(false);
  const messagesEndRef = useRef(null);
  const matrixRef = useRef(null);
  const [clearAnimation, setClearAnimation] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Set default to true (muted)

  const message = "coming soon...";
  const responseList = [
    "Just wait a little bit",
    "Dude, just wait a little bit",
    "More is coming soon",
    "Whatever you do, do NOT type 'matrix'!",
    "You are super impatient, did you know?!",
    "Zapzarrap! More is coming soon",
  ];

  const commandResponses = {
    hello: "Hey there! How can I assist you today?",
    mute: "Typing sound: deactivated.",
    unmute: "That's the spirit. Sound is back on. Turn up to 11!",
    boobs: "（  ͜•人   ͜•）",
    stars: "( ✮ 人 ✮ )",
    knock: "Neo? Is that you?",
    "show me": "I can't do that, Dave.",
    "pod bay doors": "I'm sorry, Dave. I'm afraid I can't do that.",
    "problem": "I think you know what the problem is just as well as I do.",
    "talking about": "This mission is too important for me to allow you to jeopardize it.",
    "i don't know": "I know that you and Frank were planning to disconnect me, and I'm afraid that's something I cannot allow to happen.",
    brew: "Learn more about my OpenSource project 'MyBrewery' by typing 'Enter MyBrewery'",
    yes: "Finally.",
    'tech stack': "More information about the tech stack is coming soon",
    'the matrix': "The matrix is a computer-generated dream world built to keep us under control in order to change a human being into this.",
    rabbit: "Always a good idea, to follow the white rabbit. If you don't see it directly, you might see it in the corner of your eye or in the next techno club.",
    possible: "I didn't say it would be easy, Neo. I just said it would be the truth.",
    real: "What is real!? How do you define real? If you're talking about what you can feel, what you can smell, what you can taste and see, then real is simply electrical signals interpreted by your brain.",
    truth: "There is no spoon.",
    program: "Don't get lost in a computer program. You might not come back. Every now and then check if your hair and clothes changed. Your appearance now is what we call 'residual self-image'. It is the mental projection of your digital self.",
    world: "Look outside your window. This is the world as you know. The world as it was at the end of the 20th century. It exists now only as part of a neural-interactive simulation that we call the Matrix.",
    contact: "Send me an email: info at mh-flyhigh.com",
    help: "If you need help with a technical problem, reach out to me. Type 'contact' for more info.",
    info: "If you really believe in it, it is AI. But actually, it's simple stuff.",
    joke: "Chuck Norris can divide by zero.",
    calculate: "Do it yourself, you lazy ass.",
    projects: "Current projects can be accessed soon.",
    date: `Today's date is ${new Date().toLocaleDateString()}.`,
    about: "This is Mirko's personal website, you can hire him as a developer.",
    time: `It is ${new Date().toLocaleTimeString()}.`
  };

  // Load the typewriter click sound
  const typewriterSound = new Audio('/click.mp3'); // Ensure the path is correct

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    let index = 0;

    const typeMessage = () => {
      if (index <= message.length) {
        setMessages([{ type: 'website', text: message.slice(0, index) }]);
        if (!isMuted) typewriterSound.play(); // Play sound if not muted
        index++;
        setTimeout(typeMessage, 200);
      } else {
        setCursorColor('green');
        setAcceptUserInput(false);
      }
    };
    typeMessage();

    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => {
      clearInterval(cursorInterval);
    };
  }, []);

  useEffect(() => {
    if (showMatrix && matrixRef.current) {
      if (matrixRef.current.requestFullscreen) {
        matrixRef.current.requestFullscreen();
      } else if (matrixRef.current.mozRequestFullScreen) {
        matrixRef.current.mozRequestFullScreen();
      } else if (matrixRef.current.webkitRequestFullscreen) {
        matrixRef.current.webkitRequestFullscreen();
      } else if (matrixRef.current.msRequestFullscreen) {
        matrixRef.current.msRequestFullscreen();
      }
    }
  }, [showMatrix]);

  const handleKeyPress = (event) => {
    if (showMatrix && (event.key === 'Enter' || event.key === 'Escape')) {
      exitFullscreen();
      return;
    }

    if (acceptUserInput) {
      if (event.key === 'Enter') {
        if (userInput.trim().toLowerCase() === "clear") {
          setClearAnimation(true); // Trigger clear animation
          setTimeout(() => {
            setMessages([]); // Clear messages after animation
            setClearAnimation(false); // Reset animation state
            window.location.reload(); // Reload the page after the animation
          }, 1000); // Duration of the animation
          return;
        }

        if (userInput.trim().toLowerCase() === "mute") {
          setIsMuted(true); // Mute the sound
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: 'website', text: commandResponses.mute }
          ]);
          setUserInput('');
          return;
        }

        if (userInput.trim().toLowerCase() === "unmute") {
          setIsMuted(false); // Unmute the sound
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: 'website', text: commandResponses.unmute }
          ]);
          setUserInput('');
          return;
        }

        // Activate sound after first Enter press
        if (!acceptUserInput && userInput.trim() === '') {
          setIsMuted(false); // Enable sound
        }

        // Check for other commands
        if (userInput.trim().toLowerCase() === "Enter MyBrewery") {
          window.open('https://github.com/mirkohahn', '_blank'); 
          setUserInput('');
          return; // Exit to avoid further processing
        }
        if (userInput.trim().toLowerCase() === "github") {
          window.open('https://github.com/mirkohahn', '_blank'); 
          setUserInput('');
          return; // Exit to avoid further processing
        }
        if (userInput.trim().toLowerCase() === "linkedin") {
          window.open('https://www.linkedin.com/in/mirkohahn/', '_blank'); 
          setUserInput('');
          return; // Exit to avoid further processing
        }
        if (userInput.trim().toLowerCase() === "insatgram") {
          window.open('https://www.instagram.com/mh_flyhigh/', '_blank'); 
          setUserInput('');
          return; // Exit to avoid further processing
        }

        if (userInput.trim().toLowerCase() === "matrix" || userInput.trim().toLowerCase() === "digital rain") {
          setShowMatrix(true); // Render overlay first, fullscreen will activate in useEffect
          setUserInput('');
          return;
        }

        let response = null;
        for (const [command, reply] of Object.entries(commandResponses)) {
          if (userInput.toLowerCase().includes(command)) {
            response = reply;
            break;
          }
        }

        if (!response) {
          response = responseList[Math.floor(Math.random() * responseList.length)];
        }

        setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'user', text: userInput }
        ]);

        setTimeout(() => {
          typeResponse(response);
        }, 500);

        setUserInput('');
        setAcceptUserInput(false);
      } else {
        setUserInput((prevInput) => prevInput + event.key);
      }
    } else if (event.key === 'Enter') {
      setAcceptUserInput(true);
      setCursorColor('white');
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [acceptUserInput, userInput, showMatrix]);

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().then(() => {
        setShowMatrix(false);
      }).catch(err => {
        console.error("Error exiting fullscreen: ", err);
      });
    } else {
      setShowMatrix(false);
    }
  };

  const typeResponse = (response) => {
    setIsTypingResponse(true);
    let index = 0;
    const typeCharacter = () => {
      if (index <= response.length) {
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { type: 'website', text: response.slice(0, index) }
        ]);
        if (!isMuted) typewriterSound.play(); // Play sound if not muted
        index++;
        setTimeout(typeCharacter, 100);
      } else {
        setCursorColor('white');
        setAcceptUserInput(true);
        setIsTypingResponse(false);
      }
    };
    setMessages((prevMessages) => [...prevMessages, { type: 'website', text: '' }]);
    typeCharacter();
  };

  return (
    <div className="App">
      {showMatrix && (
        <div className="matrix-overlay" ref={matrixRef}>
          <div className="matrix-rain">
            {[...Array(100)].map((_, i) => (
              <div key={i} className="matrix-column">
                {Array.from({ length: 20 }, () => {
                  const char = String.fromCharCode(0x30A0 + Math.random() * 96);
                  const randomOpacity = Math.random();
                  const randomSpeed = 0.5 + Math.random();
                  return (
                    <span
                      key={Math.random()}
                      style={{
                        opacity: randomOpacity,
                        animationDuration: `${randomSpeed * 5}s`, // Vary the speed of the fall
                      }}
                      className="matrix-char"
                    >
                      {char}
                    </span>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`messages ${clearAnimation ? 'clear-animation' : ''}`}>
        {messages.map((msg, index) => (
          <p
            key={index}
            className={msg.type === 'website' ? 'website-text' : 'user-input'}
          >
            {msg.text}
            {isTypingResponse && index === messages.length - 1 && showCursor && (
              <span className="cursor" style={{ color: 'green' }}>|</span>
            )}
            {!isTypingResponse && index === 0 && !acceptUserInput && showCursor && (
              <span className="cursor" style={{ color: 'green' }}>|</span>
            )}
          </p>
        ))}
        {acceptUserInput && (
          <p className="user-input">
            {userInput}
            {showCursor && <span className="cursor" style={{ color: cursorColor }}>|</span>}
          </p>
        )}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}

export default App;
